<template>
    <div class="videoBox">
        <el-tabs v-model="ShortTab" @tab-click="handleClick">
            <el-tab-pane label="项目列表" name="first">
                <VideoProjectList v-if="ShortTab == 'first'"></VideoProjectList>
            </el-tab-pane>
            <el-tab-pane label="剪辑库" name="second">
                <ClipLiabrary v-if="ShortTab == 'second'"></ClipLiabrary>
            </el-tab-pane>
            <el-tab-pane label="视频列表" name="third">
                <VideoList v-if="ShortTab == 'third'"></VideoList>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import VideoProjectList from "../../../components/creativecentermodule/VideoProjectList.vue";
    import ClipLiabrary from "../../../components/creativecentermodule/ClipLiabrary.vue";
    import VideoList from "../../../components/creativecentermodule/VideoList.vue";
    export default {
        name: "VideoClip",
        components: {VideoProjectList,ClipLiabrary,VideoList},
        data() {
            return {
                ShortTab: this.$route.query.ShortTab || 'first',
            }
        },
        created() {
            switch (Number(this.$route.query.ShortTab)) {
                case 1:
                    this.ShortTab = 'first';
                    break;
                case 2:
                    this.ShortTab = 'second';
                    break;
                case 3:
                    this.ShortTab = 'third';
                    break;
            }
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            }
        }
    }
</script>

<style scoped lang="scss">
    .videoBox {
        background: #ffffff;

        ::v-deep.el-tab-pane{
            height: 100%;
        }
        ::v-deep .el-tabs__header {
            padding: 35px 35px 0 35px;
        }

        ::v-deep .el-tabs__item.is-active {
            color: #303133;
        }

        ::v-deep .el-tabs__item {
            font-size: 18px;
        }

        ::v-deep .el-tabs__active-bar {
            background-color: #9ACC5B;
        }

        ::v-deep .el-tabs__active-bar:before {
            position: absolute;
            top: -13px;
            left: 40%;
            content: '';
            width: 0;
            height: 0;
            border-style: dashed dashed solid dashed;
            border-width: 7px;
            border-color: transparent transparent #9ACC5B transparent;
        }

        ::v-deep .el-tabs__content {
            padding: 0 35px;
        }
    }
</style>