<template>
  <div class="account-content">
    <div class="selectClass">
      <div class="select-left">
        <span>筛选</span>
        <el-select style="margin: 0 16px" size="mini" v-model="studentPages.status" placeholder="请选择" @change="selectAudit">
          <el-option v-for="item in auditList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <el-table :data="videoList" style="width: 100%;flex: 1;" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', height: '50px', color: '#222222', background: '#F8F8FD',fontSize: '14px'}"
      :cell-style="{fontSize: '14px',color: '#333333',height: '75px'}">
      <el-table-column prop="title" label="标题" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="cover" label="视频封面">
        <template slot-scope="scope">
          <div style="width: 120px; height: 60px;"> <img style="width: 100%;height: 100%;object-fit: cover;" :src="scope.row.cover" alt=""></div>
        </template>
      </el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1">待审核</span>
          <span style="color: #9ACC5B" v-if="scope.row.status === 2">已通过</span>
          <span style="color: #FF0000" v-if="scope.row.status === 3">未通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" align="center" label="发布时间">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" style="margin-right: 10px;" @click="viewVideo(scope.row)">预览</el-link>
          <el-link :underline="false" type="danger" style="margin-right: 10px;" @click="deleteSingle(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pages-center" background style="padding-bottom: 10px;" :current-page="studentPages.page" :page-size="studentPages.limit" layout="prev, pager, next, jumper" :total="total" @current-change="studentCurrentChange">
    </el-pagination>
    <el-dialog title="视频详情" :visible.sync="dialogVideoDetail" width="900px" class="VideoDetail-dialog" :before-close="handleClose">
      <video width="100%" height="500px" :src="videoDetailData.videoUrl" controls="true" controlslist="nodownload"></video>
    </el-dialog>
  </div>
</template>

<script>
import { getVideoList, deleteVideo } from "@/utils/apis";
export default {
  name: "VideoModule",
  data() {
    return {
      dialogVideoDetail: false,
      videoList: [],
      //分页
      studentPages: {
        page: 1,
        limit: 10,
        status: -1,
      },
      videoDetailData: {
        videoUrl: "",
        create_time: "",
        id: "",
      },
      total: 0,
      auditList: [
        { label: "全部", value: -1 },
        { label: "待审核", value: 1 },
        { label: "已通过", value: 2 },
        { label: "未通过", value: 3 },
      ],
    };
  },
  mounted() {
    this.getVideoList();
  },
  methods: {
    async getVideoList() {
      const res = await getVideoList(this.studentPages);
      if (res.code === 200) {
        this.videoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.message);
      }
    },
    selectAudit() {
      this.getVideoList();
    },
    viewVideo(row) {
      this.videoDetailData.videoUrl = row.video;
      this.videoDetailData.create_time = row.create_time;
      this.videoDetailData.id = row.id;
      this.dialogVideoDetail = true;
    },

    deleteSingle(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let params = {
            id: id,
          };
          const res = await deleteVideo(params);
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getVideoList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    studentCurrentChange(val) {
      this.studentPages.page = val;
      this.getVideoList();
    },
    handleClose() {
      this.dialogVideoDetail = false;
      this.videoDetailData.videoUrl = "";
      this.videoDetailData.create_time = "";
      this.videoDetailData.videoId = "";
    },
  },
};
</script>

<style scoped lang="scss">
.account-content {
  height: calc(100vh - 200px);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  .selectClass {
    display: flex;
    padding: 27px 0px;
  }
}
</style>